import { graphql } from "@/gql/__generated__";

export const displayPayrollNotificationsQuery = graphql(`
  query DisplayPayrollNotifications {
    displayPayrollNotifications {
      displayNotifyId
      payrollReportId
      finalPaymentAmount
    }
  }
`);

export const updatePayrollNotifyMutation = graphql(`
  mutation UpdatePayrollNotify($input: UpdatePayrollNotifyInput!) {
    updatePayrollNotify(input: $input) {
      displayNotify {
        displayNotifyId
        finalPaymentAmount
      }
    }
  }
`);

export const uploadSnapshotImageMutation = graphql(`
  mutation UploadSnapshotImage($input: UploadSnapshotImageInput!) {
    uploadSnapshotImage(input: $input) {
      imageUrl
    }
  }
`);
