import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { ComboboxData, ComboboxItem } from "@mantine/core";

import { Select, SelectProps } from "@/components/ui/elements";

type Props<FORM_TYPE extends FieldValues> = {
  data: ComboboxData;
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
} & Omit<SelectProps, "data">;

const isComboboxItem = (item: any): item is ComboboxItem =>
  typeof item === "object" && "value" in item;

export const deduplicateData = (data: ComboboxData): ComboboxData => {
  const itemMap = new Map<string, ComboboxItem>();
  data.forEach((item) => {
    if (typeof item === "string") {
      itemMap.set(item, { value: item, label: item });
    } else if (isComboboxItem(item)) {
      itemMap.set(item.value, item);
    }
  });
  return Array.from(itemMap.values());
};

export function SelectField<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const { control, data, name, ...selectProps } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const deduplicatedData = deduplicateData(data);

  return (
    <Select
      id={name}
      {...selectProps}
      ref={field.ref}
      data={deduplicatedData}
      value={field.value}
      onChange={field.onChange}
      error={error?.message}
    />
  );
}
