import { ForwardedRef, forwardRef } from "react";

import {
  CheckboxProps as MantineCheckboxProps,
  Checkbox as MantineCheckbox,
} from "@mantine/core";

export type CheckboxProps = MantineCheckboxProps;
type Props = {
  onChange?: (value: string) => void;
  value?: string;
} & CheckboxProps;

export const Checkbox = forwardRef(function Checkbox(
  props: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { ...checkboxProps } = props;
  const defaultOption: CheckboxProps = {
    color: "teal",
  };
  const option = Object.assign({}, defaultOption, checkboxProps);
  return <MantineCheckbox {...option} ref={ref} />;
});
