import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";

import {
  ComboboxData,
  MultiSelect as MantineMultiSelect,
  MultiSelectProps as MantineMultiSelectProps,
  MantineStyleProps,
} from "@mantine/core";

export type MultiSelectProps = MantineMultiSelectProps & MantineStyleProps;

type Props<FORM_TYPE extends FieldValues> = {
  data: ComboboxData;
  onChange?:
    | ((value: string) => void)
    | ((
        event: ChangeEvent<Element> | PathValue<FORM_TYPE, Path<FORM_TYPE>>,
      ) => void);
} & Omit<MultiSelectProps, "data" | "onChange">;

export const MultiSelect = forwardRef(function Select<
  FORM_TYPE extends FieldValues,
>(props: Props<FORM_TYPE>, ref: ForwardedRef<HTMLInputElement>) {
  const { data, ...multiSelectProps } = props;
  const defaultOption: Omit<MultiSelectProps, "data"> = {
    placeholder: "選択してください",
  };
  const option = Object.assign({}, defaultOption, multiSelectProps);
  // @ts-ignore
  return <MantineMultiSelect data={data} {...option} ref={ref} />;
});
