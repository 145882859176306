import {
  ListProps,
  List as MantineList,
  MantineStyleProps,
} from "@mantine/core";

import { ListItem } from "./ListItem";

type Props = {
  children: React.ReactNode;
} & Omit<ListProps, "children"> &
  MantineStyleProps;

export function List(props: Props) {
  const { children, ...listProps } = props;
  const defaultOption: Omit<ListProps, "children"> = {
    size: "sm",
  };
  const option = Object.assign({}, defaultOption, listProps);
  return <MantineList {...option}>{children}</MantineList>;
}

List.Item = ListItem;
