import { Tabs as MantineTabs, TabsProps } from "@mantine/core";

import { Tab } from "./Tab";
import { TabsList } from "./TabList";
import { TabsPanel } from "./TabPanel";

type Props = {
  children: React.ReactNode;
} & Omit<TabsProps, "children">;

export function Tabs(props: Props) {
  const { children, ...tabsProps } = props;
  const defaultOption: Omit<TabsProps, "children"> = {
    radius: "md",
    keepMounted: false,
  };
  const option = Object.assign({}, defaultOption, tabsProps);
  return <MantineTabs {...option}>{children}</MantineTabs>;
}

Tabs.List = TabsList;
Tabs.Tab = Tab;
Tabs.Panel = TabsPanel;
