import { MantineStyleProps, Tabs, TabsPanelProps } from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<TabsPanelProps, "children"> &
  MantineStyleProps;

export function TabsPanel(props: Props) {
  const { children, ...tabsPanelProps } = props;
  const defaultOption: Omit<TabsPanelProps, "children" | "value"> &
    MantineStyleProps = {
    pt: "xs",
  };
  const option = Object.assign({}, defaultOption, tabsPanelProps);
  return <Tabs.Panel {...option}>{children}</Tabs.Panel>;
}
