import {
  BadgeProps,
  Badge as MantineBadge,
  MantineStyleProps,
} from "@mantine/core";

type Props = {} & BadgeProps & MantineStyleProps;

export function Badge(props: Props) {
  const { children, ...badgeProps } = props;
  const defaultOption: BadgeProps = {
    size: "xl",
    radius: "sm",
    variant: "filled",
  };
  const option = Object.assign({}, defaultOption, badgeProps);
  return <MantineBadge {...option}>{children}</MantineBadge>;
}
