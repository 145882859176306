import {
  GroupProps,
  Group as MantineGroup,
  MantineStyleProps,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<GroupProps, "children"> &
  MantineStyleProps;

export function Group(props: Props) {
  const { children, ...groupProps } = props;
  const defaultOption: Omit<GroupProps, "children"> = {};
  const option = Object.assign({}, defaultOption, groupProps);
  return <MantineGroup {...option}>{children}</MantineGroup>;
}
