import { Badge, BadgeProps, MantineStyleProps } from "@mantine/core";

type Props = {
  label: string;
} & MantineStyleProps &
  BadgeProps;

export function Chip(props: Props) {
  const { label, ...chipProps } = props;
  const defaultOption: BadgeProps = {
    size: "md",
  };
  const option = Object.assign({}, defaultOption, chipProps);
  return <Badge {...option}>{label}</Badge>;
}
