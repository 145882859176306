import { Flex, TextProps } from "@mantine/core";

import { Text, Title } from "@/components/ui/elements";

type Props = {
  icon: React.ReactNode;
  label: string | React.ReactNode;
  title?: string;
  align?: React.CSSProperties["alignContent"];
  labelProps?: TextProps;
};

export function IconLabel(props: Props) {
  const { icon, label, title, align, labelProps } = props;
  return (
    <Flex align={align ? align : "center"} gap={5}>
      <Flex align={align ? align : "center"} py={4}>
        {icon}
      </Flex>
      <div>
        {title && <Title.H4>{title}</Title.H4>}
        <Text style={{ whiteSpace: "pre-wrap" }} {...labelProps}>
          {label}
        </Text>
      </div>
    </Flex>
  );
}
