import {
  AiFillHeart,
  AiFillPhone,
  AiFillSafetyCertificate,
  AiOutlineClose,
  AiOutlineHeart,
} from "react-icons/ai";
import { BiArrowBack, BiSprayCan, BiTimeFive } from "react-icons/bi";
import { BsBag, BsCardText } from "react-icons/bs";
import {
  FaCarSide,
  FaCheck,
  FaEdit,
  FaExclamationCircle,
  FaInfo,
  FaMapMarkerAlt,
  FaPlus,
  FaStar,
  FaUserTie,
} from "react-icons/fa";
import { GrDislike, GrLike } from "react-icons/gr";
import { IconBaseProps } from "react-icons/lib";
import {
  MdArrowDownward,
  MdArrowForwardIos,
  MdArrowUpward,
  MdHelp,
  MdPlace,
  MdTrain,
} from "react-icons/md";
import { RiAlarmWarningLine, RiMoneyCnyCircleLine } from "react-icons/ri";
import { TbLicense } from "react-icons/tb";

type Props = {} & IconBaseProps;

const iconDefaultOption = {
  size: "1rem",
};

export function CheckIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <FaCheck {...option} />;
}

export function ExclamationCircleIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <FaExclamationCircle {...option} />;
}

export function SprayCanIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <BiSprayCan {...option} />;
}

export function TimeFiveIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <BiTimeFive {...option} />;
}

export function PlaceIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <MdPlace {...option} />;
}

export function MoneyIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <RiMoneyCnyCircleLine {...option} />;
}

export function TrainIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <MdTrain {...option} />;
}

export function LicenseIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <TbLicense {...option} />;
}

export function BagIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <BsBag {...option} />;
}

export function ProfileIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <BsCardText {...option} />;
}

export function AttentionIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <RiAlarmWarningLine {...option} />;
}

export function InfoIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <FaInfo {...option} />;
}

export function ArrowForwardIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <MdArrowForwardIos {...option} />;
}

export function ArrowBackIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <BiArrowBack {...option} />;
}

export function ArrowUpIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <MdArrowUpward {...option} />;
}

export function ArrowDownIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <MdArrowDownward {...option} />;
}

export function SafetyCertificateIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <AiFillSafetyCertificate {...option} />;
}

export function HeartIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <AiFillHeart {...option} />;
}

export function HeartOutlineIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <AiOutlineHeart {...option} />;
}

export function LikeIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <GrLike {...option} />;
}

export function DisLikeIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <GrDislike {...option} />;
}

export function MarkerIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <FaMapMarkerAlt {...option} />;
}

export function PhoneIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <AiFillPhone {...option} />;
}

export function CloseIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <AiOutlineClose {...option} />;
}

export function StarIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <FaStar {...option} />;
}

export function PlusIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <FaPlus {...option} />;
}

export function HelpIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <MdHelp {...option} />;
}

export function EditIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <FaEdit {...option} />;
}

export function CarIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <FaCarSide {...option} />;
}

export function UserTieIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <FaUserTie {...option} />;
}
