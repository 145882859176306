import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";

import {
  Switch as MantineSwitch,
  SwitchProps as MantineSwitchProps,
} from "@mantine/core";

export type SwitchProps = MantineSwitchProps;

type Props<FORM_TYPE extends FieldValues> = {
  onChange?:
    | ((value: string) => void)
    | ((
        event: ChangeEvent<Element> | PathValue<FORM_TYPE, Path<FORM_TYPE>>,
      ) => void);
  value?: string;
} & Omit<SwitchProps, "onChange">;

export const Switch = forwardRef(function Switch<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { ...fileInputProps } = props;
  const defaultOption: SwitchProps = {};
  const option = Object.assign({}, defaultOption, fileInputProps);
  return <MantineSwitch {...option} ref={ref} />;
});
