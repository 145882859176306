import { Text } from "@mantine/core";

import classes from "./_css/DateItem.module.css";

type Props = {
  date: string;
  dayWeek: string;
  isSelected: boolean;
};

export function DateItem(props: Props) {
  const { date, dayWeek, isSelected } = props;
  return (
    <div data-selected={isSelected || undefined} className={classes.dateItem}>
      <Text fz="lg" fw={400} pt={5}>
        {date}
      </Text>
      <Text fz="sm" c="gray" pb={5}>
        {dayWeek}
      </Text>
    </div>
  );
}
