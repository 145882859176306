import { Tabs, TabsListProps } from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<TabsListProps, "children">;

export function TabsList(props: Props) {
  const { children, ...tabsListProps } = props;
  const defaultOption: Omit<TabsListProps, "children"> = {
    grow: true,
  };
  const option = Object.assign({}, defaultOption, tabsListProps);
  return <Tabs.List {...option}>{children}</Tabs.List>;
}
