import {
  MantineStyleProps,
  Text as MantineText,
  TextProps,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
  lineBreak?: boolean;
} & MantineStyleProps &
  TextProps;

export function Span(props: Props) {
  const { children, lineBreak, ...textProps } = props;
  const defaultOption: Omit<TextProps, "children"> = {
    fz: "sm",
  };
  const option = Object.assign({}, defaultOption, textProps);

  return (
    <MantineText
      component="span"
      {...option}
      style={{ whiteSpace: "pre-wrap" }}
    >
      {children}
    </MantineText>
  );
}
