import {
  format,
  eachDayOfInterval,
  parse,
  differenceInMinutes,
  parseISO,
} from "date-fns";
import { ja } from "date-fns/locale";

export type BreakTime = {
  startBreakTime?: string | null | undefined;
  endBreakTime?: string | null | undefined;
};

export function getFromNextWeek(targetDay?: Date) {
  const today = targetDay ? targetDay : new Date();
  const nextWeek = new Date(today.getTime() + 5 * 24 * 60 * 60 * 1000);
  const dates = eachDayOfInterval({ start: today, end: nextWeek });
  return dates;
}

export function getFormatedDatetimes(dates: Date[], formatCode: string) {
  return dates.map((date) => format(date, formatCode));
}

export function getDayOfWeek(date: Date, formatCode?: string) {
  const code = formatCode ? formatCode : "EEEEE";
  const formattedDayOfWeek = format(date, code, { locale: ja });
  return formattedDayOfWeek;
}

export function getTimeDifferenceInMinutes(startTime: string, endTime: string) {
  const startDate = parse(startTime, "HH:mm", new Date());
  const endDate = parse(endTime, "HH:mm", new Date());

  if (endDate < startDate) {
    endDate.setDate(endDate.getDate() + 1);
  }

  return differenceInMinutes(endDate, startDate);
}

export function getBreakTimesDifferenceInMinutes(breakTimes: BreakTime[]) {
  return breakTimes
    .map((breakTime) => {
      if (breakTime.startBreakTime && breakTime.endBreakTime) {
        return getTimeDifferenceInMinutes(
          breakTime.startBreakTime,
          breakTime.endBreakTime,
        );
      } else {
        return 0;
      }
    })
    .reduce((sum, num) => sum + num, 0);
}

export const formatDate = (date: string | null | undefined): string => {
  if (!date) {
    return "-月-日";
  }
  const d = parseISO(date);
  return format(d, "M月d日");
};

export const formatTime = (time: string | null | undefined): string => {
  if (!time) {
    return "--:--";
  }
  const d = parseISO(time);
  return format(d, "HH:mm");
};
