"use client";
import { ReactNode } from "react";
import {
  ArrayPath,
  Control,
  FieldArrayWithId,
  FieldValues,
  Path,
  useFieldArray,
} from "react-hook-form";

import { CloseButton, Stack } from "@mantine/core";

import { Button, Group } from "@/components/ui/elements";
import {
  DatePickerField,
  InputField,
  TextAreaField,
} from "@/components/ui/parts";

import { createObjectFromArray } from "@/util";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: ArrayPath<FORM_TYPE>;
  title: string;
  fieldObj: {
    label: string;
    fieldName: string;
    type: "input" | "textArea" | "date" | "time" | "number";
    w?: any;
    rightSection?: ReactNode;
    withAsterisk?: boolean;
  }[];
  limitLength?: number;
};

export function FlexibleField<FORM_TYPE extends FieldValues>({
  control,
  name,
  title,
  fieldObj,
  limitLength,
}: Props<FORM_TYPE>) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });
  const handleAppend = () => {
    if (!!limitLength && limitLength <= fields.length) {
      return;
    }
    const obj = createObjectFromArray(fields);
    append(obj as FieldArrayWithId<FORM_TYPE, ArrayPath<FORM_TYPE>, "id">);
  };
  const handleRemove = (index?: number | number[]) => {
    if (fields.length === 1) return;
    remove(index);
  };

  return (
    <Stack>
      {fields.map((field, index) => (
        <Group key={field.id} align="flex-end">
          {fieldObj.map((f, i) => {
            return (
              <Field
                key={i}
                index={index}
                control={control}
                name={name}
                {...f}
              />
            );
          })}
          <CloseButton
            onClick={() => handleRemove(index)}
            mb={"5px"}
            mt={"auto"}
          />
        </Group>
      ))}

      <Button variant="outline" onClick={handleAppend}>
        {title}追加
      </Button>
    </Stack>
  );
}

type FieldProps<FORM_TYPE extends FieldValues> = {
  label: string;
  fieldName: string;
  type: "input" | "textArea" | "date" | "time" | "number";
  index: number;
  control: Control<FORM_TYPE>;
  name: ArrayPath<FORM_TYPE>;
  rightSection?: ReactNode;
  w?: any;
  withAsterisk?: boolean;
};

function Field<FORM_TYPE extends FieldValues>({
  label,
  fieldName,
  type,
  index,
  control,
  name,
  rightSection,
  w,
  withAsterisk,
}: FieldProps<FORM_TYPE>) {
  return (
    <>
      {type === "textArea" && (
        <TextAreaField
          label={label}
          control={control}
          withAsterisk={withAsterisk}
          w={w}
          name={
            `${name}.${index}${
              fieldName ? "." + fieldName : null
            }` as Path<FORM_TYPE>
          }
        />
      )}
      {type === "date" && (
        <DatePickerField
          label={label}
          w={w}
          withAsterisk={withAsterisk}
          name={
            `${name}.${index}${
              fieldName ? "." + fieldName : null
            }` as Path<FORM_TYPE>
          }
          control={control}
        />
      )}
      {type === "time" && (
        <InputField
          label={label}
          w={w}
          withAsterisk={withAsterisk}
          type="time"
          name={
            `${name}.${index}${
              fieldName ? "." + fieldName : null
            }` as Path<FORM_TYPE>
          }
          control={control}
        />
      )}
      {type === "input" && (
        <InputField
          label={label}
          control={control}
          rightSection={rightSection}
          withAsterisk={withAsterisk}
          w={w}
          name={
            `${name}.${index}${
              fieldName ? "." + fieldName : null
            }` as Path<FORM_TYPE>
          }
        />
      )}
      {type === "number" && (
        <InputField
          label={label}
          control={control}
          rightSection={rightSection}
          withAsterisk={withAsterisk}
          type="number"
          w={w}
          name={
            `${name}.${index}${
              fieldName ? "." + fieldName : null
            }` as Path<FORM_TYPE>
          }
        />
      )}
    </>
  );
}
