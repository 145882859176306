import {
  AvatarProps,
  Avatar as MantineAvatar,
  MantineStyleProps,
  createPolymorphicComponent,
} from "@mantine/core";

type Props = { children: React.ReactNode } & AvatarProps & MantineStyleProps;

export function _Avatar(props: Props) {
  const { children, ...avatarProps } = props;
  const defaultOption: Omit<AvatarProps, "children"> = {
    size: "xl",
    color: "teal",
  };
  const option = Object.assign({}, defaultOption, avatarProps);
  return <MantineAvatar {...option}>{children}</MantineAvatar>;
}

export const Avatar = createPolymorphicComponent<"div", AvatarProps>(_Avatar);
