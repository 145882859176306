import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";

import {
  MantineStyleProps,
  Rating as MantineRating,
  RatingProps,
} from "@mantine/core";

type Props<FORM_TYPE extends FieldValues> = {
  onChange?:
    | ((value: number) => void)
    | ((
        event: ChangeEvent<Element> | PathValue<FORM_TYPE, Path<FORM_TYPE>>,
      ) => void);
} & MantineStyleProps &
  Omit<RatingProps, "onChange">;

export const Rating = forwardRef(function Rating<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { ...RatingProps } = props;
  const defaultOption: Omit<RatingProps, "children"> = {};
  const option = Object.assign({}, defaultOption, RatingProps);
  return <MantineRating {...option} ref={ref} />;
});
