import { useState } from "react";
import {
  Control,
  FieldErrors,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { ErrorMessage } from "@hookform/error-message";
import { Input } from "@mantine/core";
import {
  Dropzone as MantineDropzone,
  FileWithPath,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import Image from "next/image";

import {
  FileInput,
  Text,
  Group,
  Avatar,
  Stack,
} from "@/components/ui/elements";

import classes from "./_css/Dropzone.module.css";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
  errors?: FieldErrors;
};

export function Dropzone<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const { control, name, errors } = props;
  const { field } = useController({
    name,
    control,
  });
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const hasImage = field.value || files.length;

  const Previews = () => {
    if (field.value && !files.length) {
      return (
        <Group className={classes.image} justify="center">
          <Image src={field.value} alt="image" fill />
        </Group>
      );
    }
    return files.map((file, index) => {
      const imageUrl = URL.createObjectURL(file);
      return (
        <Group className={classes.image} justify="center" key={index}>
          <Image src={imageUrl} alt="image" fill />
        </Group>
      );
    });
  };
  const handleDrop = (file: FileWithPath[]) => {
    console.log(file);
    setFiles(file);
    // TODO: タイプエラー修正必要
    // @ts-ignore
    field.onChange(file);
  };
  // 既存のIMAGE_MIME_TYPEに'image/heic'を追加
  const CUSTOM_IMAGE_MIME_TYPE = [...IMAGE_MIME_TYPE, "image/heic"];
  return (
    <div>
      <MantineDropzone
        accept={CUSTOM_IMAGE_MIME_TYPE}
        onDrop={(file) => handleDrop(file)}
      >
        <Group justify="center">
          <FileInput
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            ref={field.ref}
            disabled
            style={{ display: "none" }}
          />
          {!hasImage && (
            <Stack align="center">
              <Avatar color="gray" />
              <Text>画像を選択してください</Text>
            </Stack>
          )}
          <Previews />
        </Group>
      </MantineDropzone>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <Input.Error>{message}</Input.Error>}
      />
    </div>
  );
}
