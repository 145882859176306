import {
  Stack as MantineStack,
  MantineStyleProps,
  StackProps,
  createPolymorphicComponent,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<StackProps, "children"> &
  MantineStyleProps;

function _Stack(props: Props) {
  const { children, ...stackProps } = props;
  const defaultOption: Omit<StackProps, "children"> = {};
  const option = Object.assign({}, defaultOption, stackProps);
  return <MantineStack {...option}>{children}</MantineStack>;
}

export const Stack = createPolymorphicComponent<
  "div",
  Omit<StackProps, "children">
>(_Stack);
