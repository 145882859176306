import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";

import {
  ComboboxData,
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
  MantineStyleProps,
} from "@mantine/core";

export type SelectProps = MantineSelectProps & MantineStyleProps;

type Props<FORM_TYPE extends FieldValues> = {
  data: ComboboxData;
  onChange?:
    | ((value: string) => void)
    | ((
        event: ChangeEvent<Element> | PathValue<FORM_TYPE, Path<FORM_TYPE>>,
      ) => void);
} & Omit<SelectProps, "data" | "onChange">;

export const Select = forwardRef(function Select<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { data, ...selectProps } = props;
  const defaultOption: Omit<SelectProps, "data"> = {
    placeholder: "選択してください",
  };
  const option = Object.assign({}, defaultOption, selectProps);
  // @ts-ignore
  return <MantineSelect data={data} {...option} ref={ref} />;
});
