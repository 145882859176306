import { Alert, AlertProps, rem } from "@mantine/core";

import { ExclamationCircleIcon, InfoIcon } from "@/components/ui/elements";

type Props = {
  type: "danger" | "warning" | "caution" | "notice";
  title?: string;
  children: React.ReactNode;
} & Omit<AlertProps, "title" | "children">;

export function AlertField(props: Props) {
  const { type, title, children, ...alertProps } = props;
  function getOption(_title: string | undefined) {
    const title = _title ? _title : type;
    if (type === "danger") {
      return {
        title: title,
        color: "red",
        icon: <ExclamationCircleIcon />,
      };
    }
    if (type === "warning") {
      return {
        title: title,
        color: "orange",
        icon: <ExclamationCircleIcon />,
      };
    }
    if (type === "caution") {
      return {
        title: title,
        color: "yellow",
        icon: <ExclamationCircleIcon />,
      };
    }
    if (type === "notice") {
      return {
        title: title,
        color: "green",
        icon: <InfoIcon />,
      };
    }
  }
  const defaultOption: Omit<AlertProps, "title" | "children"> = {
    variant: "outline",
    // pr: rem(10),
  };
  const option = Object.assign({}, defaultOption, getOption(title), alertProps);
  return (
    <Alert
      {...option}
      styles={{ icon: { marginRight: 0 }, root: { padding: rem(8) } }}
    >
      {children}
    </Alert>
  );
}
