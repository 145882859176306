import { Children, cloneElement } from "react";

import {
  CardProps,
  Card as MantineCard,
  MantineStyleProps,
  createPolymorphicComponent,
} from "@mantine/core";

import { CardSection } from "./CardSection";

type Props = {
  children: React.ReactNode;
} & Omit<CardProps, "children"> &
  MantineStyleProps;

const _Card = (props: Props) => {
  const { children, ...cardProps } = props;
  const defaultOption: Omit<CardProps, "children"> = {
    withBorder: true,
    radius: "md",
    shadow: "md",
  };
  const option = Object.assign({}, defaultOption, cardProps);
  const _children = Children.toArray(children);
  const content = _children.map((child, index) => {
    if (
      typeof child === "object" &&
      child &&
      "type" in child &&
      child.type === CardSection
    ) {
      return cloneElement(child, {
        "data-first": index === 0 || undefined,
        "data-last": index === _children.length - 1 || undefined,
      });
    }

    return child;
  });
  return <MantineCard {...option}>{content}</MantineCard>;
};

_Card.Section = CardSection;

export const Card = createPolymorphicComponent<
  "div",
  CardProps,
  { Section: typeof CardSection }
>(_Card);
