import { useCallback, useRef } from "react";

import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";

import { Loading } from "../Loading";

type Props = {
  latitude: number;
  longitude: number;
  officeName: string;
};

export function GoogleMaps(props: Props) {
  const { latitude, longitude, officeName } = props;

  const googleMapsApiKey = process.env
    .NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string;
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
    language: "ja",
  });
  const mapRef = useRef<google.maps.Map>();
  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);
  const onUnmount = useCallback(() => {
    mapRef.current = undefined;
  }, []);

  const containerStyle = {
    height: "300px",
    width: "100%",
  };

  return (
    <div style={{ height: "300px", width: "100%" }}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: latitude, lng: longitude }}
          options={{
            zoom: 18,
            mapTypeControl: false,
            streetViewControl: false,
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <MarkerF
            position={{ lat: latitude, lng: longitude }}
            label={{ text: officeName, fontWeight: "bold" }}
          />
        </GoogleMap>
      ) : (
        <Loading />
      )}
    </div>
  );
}
