import { Dispatch, SetStateAction } from "react";

import { ActionIcon, Grid, Group, UnstyledButton } from "@mantine/core";
import { format } from "date-fns";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import { getDayOfWeek } from "@/libs/date";

import { Container, DateItem, Paper, Text } from "@/components/ui/elements/";

import classes from "./_css/HorizontalDatePicker.module.css";

type Props = {
  selectDate: Date;
  dateItemList: Date[];
  setSelectDate: Dispatch<SetStateAction<Date>>;
  handleNextWeek: () => void;
  handlePrevWeek: () => void;
};

export function HorizontalDatePicker(props: Props) {
  const {
    dateItemList,
    setSelectDate,
    handleNextWeek,
    handlePrevWeek,
    selectDate,
  } = props;

  const initItem = dateItemList[0];
  const initMonth = format(initItem, "M");

  return (
    <Paper className={classes.paper}>
      <Container p={0}>
        <Text ta="center" fz="xl" fw="bold" mb={10}>
          {initMonth}月
        </Text>
        <Group
          justify="space-between"
          align="center"
          gap="sm"
          h={50}
          wrap="nowrap"
        >
          <ActionIcon h="100%" size="lg" onClick={handlePrevWeek}>
            <FaAngleLeft size={"1.5rem"} />
          </ActionIcon>
          <Grid w="100%">
            {dateItemList.map((d, i) => (
              <Grid.Col
                className={classes.dateItem}
                key={d.toString()}
                span={"auto"}
              >
                <UnstyledButton onClick={() => setSelectDate(d)}>
                  <DateItem
                    date={format(d, "dd")}
                    dayWeek={getDayOfWeek(d)}
                    isSelected={selectDate === d}
                  />
                </UnstyledButton>
              </Grid.Col>
            ))}
          </Grid>
          <ActionIcon h="100%" onClick={handleNextWeek}>
            <FaAngleRight size={"1.5rem"} />
          </ActionIcon>
        </Group>
      </Container>
    </Paper>
  );
}
