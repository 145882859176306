import {
  DividerProps,
  Divider as MantineDivider,
  MantineStyleProps,
} from "@mantine/core";

type Props = {} & DividerProps & MantineStyleProps;

export function Divider(props: Props) {
  const { ...dividerProps } = props;
  const defaultOption: DividerProps = {};
  const option = Object.assign({}, defaultOption, dividerProps);
  return <MantineDivider {...option} />;
}
