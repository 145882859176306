import { IconBaseProps } from "react-icons";

import { BsFacebook, BsLine } from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";

type Props = {} & IconBaseProps;

const iconDefaultOption = {
  size: "1rem",
};

export function FaceBookIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <BsFacebook {...option} />;
}

export function LineIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <BsLine {...option} />;
}

export function XIcon(props: Props) {
  const { ...iconProps } = props;
  const defaultOption: IconBaseProps = {
    ...iconDefaultOption,
  };
  const option = Object.assign({}, defaultOption, iconProps);
  return <RiTwitterXFill {...option} />;
}
