import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import { ComboboxData, ComboboxItem, ComboboxItemGroup } from "@mantine/core";

import {
  Radio,
  RadioGroup,
  RadioGroupProps,
  Group,
  Stack,
} from "@/components/ui/elements";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
  data: ComboboxData;
  row?: boolean;
} & Omit<RadioGroupProps, "name" | "children">;

export function RadioField<FORM_TYPE extends FieldValues>({
  control,
  name,
  data,
  row,
  ...radioProps
}: Props<FORM_TYPE>) {
  const RadioWrapper = ({ children }: { children: React.ReactNode }) =>
    row ? <Group>{children}</Group> : <Stack>{children}</Stack>;

  const renderRadio = (
    item: string | ComboboxItem | ComboboxItemGroup<string | ComboboxItem>,
    i: string | number,
  ) => {
    if (typeof item === "string") {
      return <Radio key={i} label={item} value={item} />;
    } else if ("label" in item && "value" in item) {
      return <Radio key={i} label={item.label} value={item.value} />;
    } else if ("group" in item && "items" in item) {
      return renderRadioGroup(item, i);
    }
  };

  const renderRadioGroup = (
    item: { group: string; items: (string | ComboboxItem)[] },
    i: string | number,
  ) => (
    <div key={i}>
      <strong>{item.group}</strong>
      {item.items.map((groupItem, groupIndex) =>
        renderRadio(groupItem, `${i}-${groupIndex}`),
      )}
    </div>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <RadioGroup
          {...field}
          {...radioProps}
          value={field.value ?? undefined}
          error={error?.message}
        >
          <RadioWrapper>
            {data.map((item, i) => renderRadio(item, i))}
          </RadioWrapper>
        </RadioGroup>
      )}
    />
  );
}
