import {
  DataTableColumn,
  DataTableProps,
  DataTable as MantineDataTable,
} from "mantine-datatable";

import { H2 } from "../title";

type Props<T> = {
  columns: DataTableColumn<T>[];
  title?: string;
} & DataTableProps<T>;

export function DataTable<T>(props: Props<T>) {
  const { title, columns, ...dataTableProps } = props;
  const defaultOption: Omit<DataTableProps<T>, "columns"> = {
    striped: true,
    verticalSpacing: "sm",
    fz: "sm",
    horizontalSpacing: "md",
    minHeight: 100,
  };
  const option = Object.assign({}, defaultOption, dataTableProps);
  return (
    <>
      {title && (
        <H2 p="lg" style={{ borderBottom: "1px solid rgb(222, 226, 230)" }}>
          {title}
        </H2>
      )}
      <MantineDataTable {...option} columns={columns} />
    </>
  );
}
