import { Stack, Title, Text } from "@/components/ui/elements";

type Props = {
  title: string;
  description?: string;
};

export function PageTitle(props: Props) {
  const { title, description } = props;
  return (
    <Stack my={10} gap="xs">
      <Title.H1>{title}</Title.H1>
      {description && <Text size="sm">{description}</Text>}
    </Stack>
  );
}
