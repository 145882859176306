import { FieldValues } from "react-hook-form";

import { Popover } from "@mantine/core";
import { DateValue, DatesRangeValue } from "@mantine/dates";

import { Calendar, DatePickerProps } from "@/components/ui/elements";

type Props<FORM_TYPE extends FieldValues> = {
  children: React.ReactNode;
  opend: boolean;
  onChange?: (value: DateValue | DatesRangeValue | Date[]) => void;
} & Omit<DatePickerProps, "children" | "onChange">;

export function DatePicker<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const { children, opend, ...datePickerProps } = props;
  return (
    <Popover
      opened={opend}
      position="bottom-start"
      trapFocus={false}
      withRoles={false}
    >
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown>
        <Calendar {...datePickerProps} />
      </Popover.Dropdown>
    </Popover>
  );
}
