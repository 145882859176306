import {
  FlexProps,
  Flex as MantineFlex,
  MantineStyleProps,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<FlexProps, "children"> &
  MantineStyleProps;

export function Flex(props: Props) {
  const { children, ...flexProps } = props;
  const defaultOption: Omit<FlexProps, "children"> = {};
  const option = Object.assign({}, defaultOption, flexProps);
  return <MantineFlex {...option}>{children}</MantineFlex>;
}
