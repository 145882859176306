"use client";
import { useMutation } from "@apollo/client";
import { Modal, Image } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import html2canvas from "html2canvas";

import { Button, Group } from "@/components/ui/elements";

import classes from "./_css/PayrollConfirmModal.module.css";
import { uploadSnapshotImageMutation } from "./_graphql";

type Props = {
  finalPaymentAmount: number;
  handleSubmit: () => void;
  opened: boolean;
  keySuffix: string;
  zIndexOffset: number;
};

// 最大幅と最大高さ、フッターの高さを定数として定義
const MAX_WIDTH = 391;
const MAX_HEIGHT = 845;
const FOOTER_HEIGHT = 70;

export function PayrollConfirmModal({
  finalPaymentAmount,
  handleSubmit,
  opened,
  keySuffix,
  zIndexOffset,
}: Props) {
  const { height, width } = useViewportSize();
  const [mutation, { loading }] = useMutation(uploadSnapshotImageMutation);
  const modalId = `payroll-confirm-modal-${keySuffix}`;
  const footerId = `payroll-confirm-footer-${keySuffix}`;
  const handleTweetX = async () => {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modalCanvas = await html2canvas(modalElement, {
        width: MAX_WIDTH - 1,
        height: MAX_HEIGHT - FOOTER_HEIGHT - 1,
        scale: 1,
      });
      const modalUrl = modalCanvas.toDataURL("image/png");
      const twitterXPostUrl =
        "https://twitter.com/intent/tweet?text=%E5%A0%B1%E9%85%AC%E3%81%8C%E6%8C%AF%E3%82%8A%E8%BE%BC%E3%81%BE%E3%82%8C%E3%81%BE%E3%81%97%E3%81%9F%EF%BC%81&url=";
      await mutation({
        variables: {
          input: {
            uploadImage: modalUrl,
          },
        },
        onError: (error) => {},
        onCompleted: (data) => {
          const imageUrl = data.uploadSnapshotImage?.imageUrl;
          imageUrl && window.open(twitterXPostUrl + imageUrl);
        },
      });
    }
  };
  const isFullscreen = width < MAX_WIDTH || height < MAX_HEIGHT;

  return (
    <Modal
      withCloseButton={false}
      onClose={() => {}}
      classNames={{
        root: classes.root,
        content: classes.modalContent,
      }}
      styles={{
        body: {
          padding: 0,
          height: "100%",
          maxHeight: MAX_HEIGHT,
          display: "flex",
          flexDirection: "column",
        },
      }}
      opened={opened}
      zIndex={300 + zIndexOffset}
      size={MAX_WIDTH}
      mah={MAX_HEIGHT}
      fullScreen={isFullscreen} // iphone 12pro以下は全画面
      centered
    >
      <div
        className={isFullscreen ? classes.modalBodyFull : classes.modalBody}
        id={modalId}
        style={{ flex: 1, overflow: "auto" }}
      >
        <div className={classes.logo}>
          <Image
            src={"/images/confirm_modal/logo.png"}
            alt={"ケアリンク"}
            w={312}
            h={102}
            fit="contain"
          />
        </div>

        <div className={classes.main}>
          <div className={classes.money}>
            <p className={classes.text}>報酬が確定しました！</p>
            <Image
              src={"/images/confirm_modal/money.png"}
              alt={"報酬"}
              fit="contain"
              w={156}
              h={192}
            />
          </div>

          <p className={classes.amountText}>
            ￥{finalPaymentAmount?.toLocaleString()}
          </p>
        </div>
        {/* <Button
          size="sm"
          pos="absolute"
          left={15}
          bottom={15}
          onClick={handleTweetX}
          color="black"
          leftSection={<XIcon />}
          loading={loading}
        >
          シェアする
        </Button> */}
      </div>
      <Group justify="center" h={FOOTER_HEIGHT} bg="white" id={footerId}>
        <Button
          color="cyan"
          onClick={handleSubmit}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          OK
        </Button>
      </Group>
    </Modal>
  );
}
