import { Control, FieldPath, FieldValues } from "react-hook-form";

import { UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { Modal } from "@/components/ui/elements";
import { Dropzone } from "@/components/ui/parts";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
  label?: string;
  children: React.ReactNode;
};

export function DropModal<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const { control, name, label, children } = props;
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <UnstyledButton onClick={open}>{children}</UnstyledButton>
      <Modal title={label} opened={opened} close={close}>
        <Dropzone control={control} name={name} />
      </Modal>
    </>
  );
}
