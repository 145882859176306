import { FieldValues } from "react-hook-form";

import {
  DatePickerProps as MantineDatePickerProps,
  DatePicker as MantineDatePicker,
  DatesRangeValue,
  DateValue,
} from "@mantine/dates";

export type DatePickerProps = MantineDatePickerProps;

type Props<FORM_TYPE extends FieldValues> = {
  onChange?: (value: DateValue | DatesRangeValue | Date[]) => void;
} & Omit<DatePickerProps, "onChange">;

export function Calendar<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const { ...datePickerProps } = props;

  return <MantineDatePicker {...datePickerProps} locale={"ja"} />;
}
