import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";

import {
  NumberInput as MantineNumberInput,
  NumberInputProps as MantineNumberInputProps,
} from "@mantine/core";

export type NumberInputProps = MantineNumberInputProps;
type Props<FORM_TYPE extends FieldValues> = {
  onChange?:
    | ((value: number | "") => void)
    | ((
        event: ChangeEvent<Element> | PathValue<FORM_TYPE, Path<FORM_TYPE>>,
      ) => void);
  value?: number | "";
} & Omit<NumberInputProps, "onChange">;

export const NumberInput = forwardRef(function NumberInput<
  FORM_TYPE extends FieldValues,
>(props: Props<FORM_TYPE>, ref: ForwardedRef<HTMLInputElement>) {
  const { ...inputProps } = props;

  const defaultOption: NumberInputProps = {
    radius: "xs",
  };
  const option = Object.assign({}, defaultOption, inputProps);
  return <MantineNumberInput {...option} ref={ref} />;
});
