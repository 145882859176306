"use client";
import { useState, useMemo } from "react";

import { useSuspenseQuery, useMutation } from "@apollo/client";

import {
  displayPayrollNotificationsQuery,
  updatePayrollNotifyMutation,
} from "./_graphql";
import { PayrollConfirmModal } from "./PayrollConfirmModal";

export function PayrollServerUpdateModal() {
  const {
    data: { displayPayrollNotifications },
  } = useSuspenseQuery(displayPayrollNotificationsQuery);
  const [mutation, { loading }] = useMutation(updatePayrollNotifyMutation);
  const modalStatesInitial = useMemo(
    () => displayPayrollNotifications.map(() => true),
    [displayPayrollNotifications.length],
  );
  const [modalStates, setModalStates] = useState<boolean[]>(modalStatesInitial);

  const updateModalStateAndServerState = async (
    index: number,
    state: boolean,
  ) => {
    await mutation({
      variables: {
        input: {
          displayNotifyId:
            displayPayrollNotifications[index]?.displayNotifyId || "",
        },
      },
      onError: (error) => {},
      onCompleted: (data) => {
        updateModalState(index, state);
      },
      refetchQueries: ["DisplayPayrollNotifications"],
    });
    updateModalState(index, state);
  };

  const updateModalState = (index: number, state: boolean) => {
    const newModalStates = modalStates.map((n) => n);
    newModalStates[index] = state;
    setModalStates(newModalStates);
  };

  return (
    <>
      {displayPayrollNotifications.map((displayPayrollNotification, index) => (
        <PayrollConfirmModal
          finalPaymentAmount={
            displayPayrollNotification?.finalPaymentAmount || 0
          }
          handleSubmit={() => updateModalStateAndServerState(index, false)}
          opened={modalStates[index]}
          key={index}
          keySuffix={index.toString()}
          zIndexOffset={displayPayrollNotifications.length - index}
        />
      ))}
    </>
  );
}
