import {
  LoaderProps,
  Loader as MantineLoader,
  MantineStyleProps,
} from "@mantine/core";

type Props = {} & MantineStyleProps & LoaderProps;

export function Loader(props: Props) {
  const { ...loaderPorps } = props;
  const defaultOption: LoaderProps = {};
  const option = Object.assign({}, defaultOption, loaderPorps);
  return <MantineLoader {...option} />;
}
