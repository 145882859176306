import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { TextArea, TextareaProps } from "@/components/ui/elements";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
} & Omit<TextareaProps, "name">;

export function TextAreaField<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const { control, name, ...textAreaProps } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  return (
    <TextArea
      id={name}
      {...textAreaProps}
      ref={field.ref}
      value={field.value}
      onChange={field.onChange}
      error={error?.message}
    />
  );
}
