import {
  Grid as MantaineGrid,
  GridProps,
  MantineStyleProps,
} from "@mantine/core";

import { GridCol } from "./GridCol";

type Props = {
  children: React.ReactNode;
} & Omit<GridProps, "children"> &
  MantineStyleProps;

export function Grid(props: Props) {
  const { children, ...gridProps } = props;
  const defaultOption: Omit<GridProps, "children"> = {};
  const option = Object.assign({}, defaultOption, gridProps);
  return <MantaineGrid {...option}>{children}</MantaineGrid>;
}

Grid.Col = GridCol;
