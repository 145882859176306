import { ChangeEvent, ForwardedRef, forwardRef, useRef } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";

import { TextInput, TextInputProps } from "@mantine/core";
import { useMergedRef } from "@mantine/hooks";

export type InputProps = TextInputProps;
type Props<FORM_TYPE extends FieldValues> = {
  onChange?:
    | ((value: string) => void)
    | ((
        event: ChangeEvent<Element> | PathValue<FORM_TYPE, Path<FORM_TYPE>>,
      ) => void);
  value?: string;
} & Omit<InputProps, "onChange">;

export const Input = forwardRef(function Input<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { ...inputProps } = props;
  const _ref = useRef<HTMLInputElement>(null);
  const mergedRef = useMergedRef(ref, _ref);
  const defaultOption: InputProps = {
    radius: "xs",
  };
  const option = Object.assign({}, defaultOption, inputProps);
  if (inputProps.type === "time") {
    const handleClick = () => {
      if (_ref.current) {
        _ref.current.showPicker();
      }
    };
    return <TextInput {...option} onClick={handleClick} ref={mergedRef} />;
  }
  return <TextInput type="text" {...option} ref={mergedRef} />;
});
