import {
  ContainerProps,
  Container as MantineContainer,
  MantineStyleProps,
} from "@mantine/core";

type Props = ContainerProps & MantineStyleProps;

export function Container(props: Props) {
  const { children, ...cotainerProps } = props;
  const defaultOption: Omit<ContainerProps, "children"> = {};
  const option = Object.assign({}, defaultOption, cotainerProps);
  return <MantineContainer {...option}>{children}</MantineContainer>;
}
