import {
  LoadingOverlay as MantineLoadingOverlay,
  LoadingOverlayProps,
  MantineStyleProps,
} from "@mantine/core";

type Props = {} & MantineStyleProps & LoadingOverlayProps;

export function LoadingOverlay(props: Props) {
  const { ...loaderPorps } = props;
  const defaultOption: LoadingOverlayProps = {
    visible: true,
  };
  const option = Object.assign({}, defaultOption, loaderPorps);
  return <MantineLoadingOverlay {...option} />;
}
