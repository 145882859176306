import { TabsTabProps, Tabs } from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<TabsTabProps, "children">;

export function Tab(props: Props) {
  const { children, ...tabProps } = props;
  const defaultOption: Omit<TabsTabProps, "children" | "value"> = {};
  const option = Object.assign({}, defaultOption, tabProps);
  return <Tabs.Tab {...option}>{children}</Tabs.Tab>;
}
