"use client";
import { useState } from "react";

import { PayrollConfirmModal } from "@/components/ui/parts";

type Props = {
  finalPaymentAmount: number;
  onCompleted: () => void;
};
export function PayrollSimpleDisplayModal({
  finalPaymentAmount,
  onCompleted,
}: Props) {
  const [opened, setOpened] = useState<boolean>(true);
  const handleSubmit = (): void => {
    setOpened(false);
    onCompleted();
  };

  return (
    <PayrollConfirmModal
      finalPaymentAmount={finalPaymentAmount}
      handleSubmit={handleSubmit}
      opened={opened}
      key={0}
      keySuffix={"0"}
      zIndexOffset={0}
    />
  );
}
