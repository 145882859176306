import { ReactNode } from "react";

import {
  Notification as MantineNotification,
  MantineStyleProps,
  NotificationProps,
} from "@mantine/core";

type Props = {
  children: ReactNode;
  type: "info" | "caution" | "error";
} & MantineStyleProps &
  Omit<NotificationProps, "children">;

export function Notification(props: Props) {
  const { children, type, ...notificationProps } = props;
  const color =
    type === "caution" ? "yellow" : type === "error" ? "red" : "bule";
  const defaultOption: NotificationProps = {
    color: color,
  };
  const option = Object.assign({}, defaultOption, notificationProps);
  return <MantineNotification {...option}>{children}</MantineNotification>;
}
