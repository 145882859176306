import { ForwardedRef, forwardRef } from "react";

import {
  Radio as MantineRadio,
  RadioProps as MantineRadioProps,
} from "@mantine/core";

export type RadioProps = MantineRadioProps;
type Props = {
  onChange?: (value: string) => void;
  value?: string;
} & RadioProps;

export const Radio = forwardRef(function Radio(
  props: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { ...radioProps } = props;
  const defaultOption: RadioProps = {};
  const option = Object.assign({}, defaultOption, radioProps);
  return <MantineRadio {...option} ref={ref} />;
});
