"use client";
import {
  MantineStyleProps,
  Title as MantineTitle,
  TitleProps,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & MantineStyleProps &
  TitleProps;

export const Title = {
  H1: function H1(props: Props) {
    const { children, ...titleProps } = props;
    const defaultOption: TitleProps = {
      order: 1,
      c: "gray.7",
    };
    const option = Object.assign({}, defaultOption, titleProps);
    return <MantineTitle {...option}>{children}</MantineTitle>;
  },
  H2: function H2(props: Props) {
    const { children, ...titleProps } = props;
    const defaultOption: TitleProps = {
      order: 2,
      c: "gray.8",
    };
    const option = Object.assign({}, defaultOption, titleProps);
    return <MantineTitle {...option}>{children}</MantineTitle>;
  },
  H3: function H3(props: Props) {
    const { children, ...titleProps } = props;
    const defaultOption: TitleProps = {
      order: 3,
      c: "gray.8",
    };
    const option = Object.assign({}, defaultOption, titleProps);
    return <MantineTitle {...option}>{children}</MantineTitle>;
  },
  H4: function H4(props: Props) {
    const { children, ...titleProps } = props;
    const defaultOption: TitleProps = {
      order: 4,
      fw: "normal",
    };
    const option = Object.assign({}, defaultOption, titleProps);
    return <MantineTitle {...option}>{children}</MantineTitle>;
  },
  H5: function H5(props: Props) {
    const { children, ...titleProps } = props;
    const defaultOption: TitleProps = {
      order: 5,
    };
    const option = Object.assign({}, defaultOption, titleProps);
    return <MantineTitle {...option}>{children}</MantineTitle>;
  },
};

export const H1 = Title.H1;
export const H2 = Title.H2;
export const H3 = Title.H3;
export const H4 = Title.H4;
export const H5 = Title.H5;
