import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";

import {
  CheckboxGroupProps as MantineRadioCheckboxGroupProps,
  Checkbox,
} from "@mantine/core";

export type CheckboxGroupProps = MantineRadioCheckboxGroupProps;
type Props<FORM_TYPE extends FieldValues> = {
  onChange?:
    | ((value: string[]) => void)
    | ((
        event: ChangeEvent<Element> | PathValue<FORM_TYPE, Path<FORM_TYPE>>,
      ) => void);
  value?: string[];
  children: React.ReactNode;
} & Omit<CheckboxGroupProps, "children" | "onChange">;

export const CheckboxGroup = forwardRef(function CheckboxGroup<
  FORM_TYPE extends FieldValues,
>(props: Props<FORM_TYPE>, ref: ForwardedRef<HTMLInputElement>) {
  const { children, ...checkboxProps } = props;
  const defaultOption: Omit<CheckboxGroupProps, "children"> = {};
  const option = Object.assign({}, defaultOption, checkboxProps);
  return (
    <Checkbox.Group {...option} ref={ref}>
      {children}
    </Checkbox.Group>
  );
});
