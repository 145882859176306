import {
  MantineStyleProps,
  Modal as MantineModal,
  ModalProps,
} from "@mantine/core";
type Props = {
  children: React.ReactNode;
  title?: string;
  opened: boolean;
  close: () => void;
} & MantineStyleProps &
  Omit<ModalProps, "children" | "title" | "opened" | "onClose">;

export function Modal(props: Props) {
  const { children, title, opened, close, ...modalProps } = props;
  return (
    <>
      <MantineModal
        opened={opened}
        onClose={close}
        title={title}
        centered
        {...modalProps}
      >
        {children}
      </MantineModal>
    </>
  );
}
