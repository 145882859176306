import {
  NavLink as MantineNavLink,
  MantineStyleProps,
  NavLinkProps,
  createPolymorphicComponent,
} from "@mantine/core";

import { ArrowForwardIcon } from "../icon";

type Props = {
  children?: React.ReactNode;
} & MantineStyleProps &
  NavLinkProps;

function _NavLink(props: Props) {
  const { children, ...navLinkProps } = props;
  const defaultOption: NavLinkProps = {
    rightSection: <ArrowForwardIcon />,
  };
  const option = Object.assign({}, defaultOption, navLinkProps);
  return <MantineNavLink {...option}>{children}</MantineNavLink>;
}

export const NavLink = createPolymorphicComponent<"button", NavLinkProps>(
  _NavLink,
);
