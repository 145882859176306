import {
  Notifications as MantineNotifications,
  NotificationsProps,
} from "@mantine/notifications";

type Props = NotificationsProps;

export function Notifications(props: Props) {
  const defaultOption: NotificationsProps = {
    limit: 3,
    autoClose: 5000,
    position: "top-center",
    zIndex: 1002,
  };
  const option = Object.assign({}, defaultOption, props);
  return <MantineNotifications {...option} />;
}
