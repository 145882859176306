import { Control, FieldPath, FieldValues, UseFormWatch } from "react-hook-form";

import {
  ComboboxData,
  Input,
  MultiSelect,
  MultiSelectProps,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { Modal } from "@/components/ui/elements";

import { CheckboxField } from "../checkboxField/CheckboxField";
import { RadioField } from "../radioField";

type Props<FORM_TYPE extends FieldValues> = {
  data: ComboboxData;
  title?: string;
  label?: string;
  type: "radio" | "checkbox";
  control: Control<FORM_TYPE>;
  watch: UseFormWatch<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
  error?: string;
} & Omit<MultiSelectProps, "data" | "value">;

export function MultiSelectModal<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const {
    data,
    title,
    type,
    control,
    watch,
    name,
    label,
    error,
    ...multiSelectProps
  } = props;
  const [opened, { open, close }] = useDisclosure(false);
  const defaultOption: Omit<MultiSelectProps, "data" | "value"> = {
    readOnly: true,
  };
  const option = Object.assign({}, defaultOption, multiSelectProps);

  const SlectItem = () => {
    if (type === "radio")
      return <RadioField data={data} control={control} name={name} px={35} />;
    return <CheckboxField data={data} control={control} name={name} px={35} />;
  };
  const value = Array.isArray(watch(name)) ? watch(name) : [watch(name)];
  return (
    <>
      <div onClick={open}>
        <MultiSelect
          value={watch(name) ? value : undefined}
          data={data}
          {...option}
          label={label}
          placeholder="選択してください"
        />
        {error && <Input.Error>{error}</Input.Error>}
      </div>
      <Modal title={label} opened={opened} close={close} size={"auto"}>
        <SlectItem />
      </Modal>
    </>
  );
}
