import {
  Card,
  CardSectionProps,
  MantineStyleProps,
  createPolymorphicComponent,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<CardSectionProps, "children"> &
  MantineStyleProps;
function _CardSection(props: Props) {
  const { children, ...cardSectionProps } = props;
  const defaultOption: Omit<CardSectionProps, "children"> = {};
  const option = Object.assign({}, defaultOption, cardSectionProps);
  return <Card.Section {...option}>{children}</Card.Section>;
}

export const CardSection = createPolymorphicComponent<
  "div",
  Omit<CardSectionProps, "children">
>(_CardSection);
