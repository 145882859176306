import {
  DatePickerInput as MantineDatePickerInput,
  DatePickerInputProps,
  DatePickerType,
} from "@mantine/dates";

type Props<Type extends DatePickerType> = DatePickerInputProps<Type>;

export function DatePickerInput<Type extends DatePickerType>({
  ...datePickerInputProps
}: Props<Type>) {
  const defaultOption: DatePickerInputProps = {
    defaultLevel: "decade",
    valueFormat: "YYYY/MM/DD",
    yearsListFormat: "YYYY",
    yearLabelFormat: "YYYY年",
    monthsListFormat: "MM",
    monthLabelFormat: "YYYY年MM月",
    hideWeekdays: true,
    locale: "ja",
  };
  const option = Object.assign({}, defaultOption, datePickerInputProps);

  return <MantineDatePickerInput {...option} />;
}
