import { Notification } from "../../elements";

type Props = {
  title: string;
  message: string;
  type: "info" | "caution" | "error";
};

export function UserNotification(props: Props) {
  const { title, message, type } = props;
  return (
    <Notification title={title} type={type}>
      {message}
    </Notification>
  );
}
