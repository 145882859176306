import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";

import {
  Textarea as MantineTextarea,
  TextareaProps as MantineTextareaProps,
} from "@mantine/core";

export type TextareaProps = MantineTextareaProps;
type Props<FORM_TYPE extends FieldValues> = {
  onChange?:
    | ((value: string) => void)
    | ((
        event: ChangeEvent<Element> | PathValue<FORM_TYPE, Path<FORM_TYPE>>,
      ) => void);
  value?: string;
} & TextareaProps;

export const TextArea = forwardRef(function TextArea<
  FORM_TYPE extends FieldValues,
>(props: Props<FORM_TYPE>, ref: ForwardedRef<HTMLTextAreaElement>) {
  const { ...textAreaProps } = props;
  const defaultOption: TextareaProps = {
    radius: "xs",
  };
  const option = Object.assign({}, defaultOption, textAreaProps);
  return <MantineTextarea {...option} ref={ref} />;
});
