import { List, ListItemProps, MantineStyleProps } from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<ListItemProps, "children"> &
  MantineStyleProps;

export function ListItem(props: Props) {
  const { children, ...listProps } = props;
  const defaultOption: Omit<ListItemProps, "children"> = {};
  const option = Object.assign({}, defaultOption, listProps);
  return <List.Item {...option}>{children}</List.Item>;
}
