import { useState } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { ComboboxData } from "@mantine/core";

import { MultiSelect, MultiSelectProps } from "@/components/ui/elements";

type Props<FORM_TYPE extends FieldValues> = {
  data: ComboboxData;
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
} & Omit<MultiSelectProps, "data">;

export function MultiSelectField<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const [isFirstChange, setFirstChange] = useState(true);
  const { control, data, name, ...MultiSelectProps } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const onChangeWithFirstChange = (value: string) => {
    if (isFirstChange) {
      setFirstChange(false);
    }
    field.onChange(value);
  };

  return (
    <MultiSelect
      id={name}
      {...MultiSelectProps}
      ref={field.ref}
      data={data}
      value={isFirstChange ? props.defaultValue : field.value}
      onChange={onChangeWithFirstChange}
      error={error?.message}
    />
  );
}
