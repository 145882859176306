import {
  MantineStyleProps,
  Paper as MantinePaper,
  PaperProps,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & MantineStyleProps &
  PaperProps;

export function Paper(props: Props) {
  const { children, ...paperProps } = props;
  const defaultOption: PaperProps = {
    shadow: "sm",
    radius: "md",
    p: "md",
  };
  const option = Object.assign({}, defaultOption, paperProps);
  return <MantinePaper {...option}>{children}</MantinePaper>;
}
