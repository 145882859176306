import { MantineStyleProps, Grid, GridColProps } from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<GridColProps, "children"> &
  MantineStyleProps;

export function GridCol(props: Props) {
  const { children, ...colProps } = props;
  const defaultOption: Omit<GridColProps, "children"> = {};
  const option = Object.assign({}, defaultOption, colProps);
  return <Grid.Col {...option}>{children}</Grid.Col>;
}
