import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { RatingProps } from "@mantine/core";

import { Rating } from "@/components/ui/elements";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
} & RatingProps;

export function RatingField<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const { control, name, ...ratingProps } = props;
  const { field } = useController({
    name,
    control,
  });
  return (
    <Rating
      id={name}
      {...ratingProps}
      ref={field.ref}
      value={field.value}
      onChange={field.onChange}
    />
  );
}
