import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import {
  DatePickerInputProps,
  DateValue,
  DatesRangeValue,
} from "@mantine/dates";

import { DatePickerInput } from "@/components/ui/elements";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
} & DatePickerInputProps;

export function DatePickerInputField<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const { control, name, ...inputProps } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const onChange = (date: DateValue | DatesRangeValue | Date[]) => {
    return field.onChange(date);
  };
  return (
    <DatePickerInput<"default">
      value={field.value}
      defaultValue={field.value}
      onChange={field.onChange}
      error={error?.message}
      {...inputProps}
    />
  );
}
