import {
  Button as MantineButton,
  ButtonProps,
  MantineStyleProps,
  createPolymorphicComponent,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & ButtonProps &
  MantineStyleProps;

function _Button(props: Props) {
  const { children, ...buttonProps } = props;
  const defaultOption: ButtonProps = {
    size: "lg",
    radius: "md",
  };
  const option = Object.assign({}, defaultOption, buttonProps);
  return <MantineButton {...option}>{children}</MantineButton>;
}

export const Button = createPolymorphicComponent<"button", ButtonProps>(
  _Button,
);
