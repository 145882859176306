import {
  IndicatorProps,
  Indicator as MantineIndicator,
  MantineStyleProps,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<IndicatorProps, "children"> &
  MantineStyleProps;

export function Indicator(props: Props) {
  const { children, ...indicatorProps } = props;
  const defaultOption: Omit<IndicatorProps, "children"> = {
    color: "red",
    size: "lg",
    position: "top-start",
    radius: "sm",
  };
  const option = Object.assign({}, defaultOption, indicatorProps);
  return <MantineIndicator {...option}>{children}</MantineIndicator>;
}
