import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import { ComboboxData, ComboboxItem, ComboboxItemGroup } from "@mantine/core";

import {
  Checkbox,
  CheckboxGroup,
  CheckboxGroupProps,
  Group,
  Stack,
} from "../../elements";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
  data: ComboboxData;
  row?: boolean;
  disabled?: boolean;
} & Omit<CheckboxGroupProps, "name" | "children">;

export function CheckboxField<FORM_TYPE extends FieldValues>({
  control,
  name,
  data,
  row,
  disabled,
  color,
  ...checkboxProps
}: Props<FORM_TYPE>) {
  const CheckBoxWrapper = ({ children }: { children: React.ReactNode }) => {
    if (row) return <Group>{children}</Group>;
    return <Stack>{children}</Stack>;
  };
  const renderCheckbox = (
    item: string | ComboboxItem | ComboboxItemGroup<string | ComboboxItem>,
    i: string | number,
  ) => {
    if (typeof item === "string") {
      return (
        <Checkbox
          color={color}
          disabled={disabled}
          key={i}
          label={item}
          value={item}
        />
      );
    } else if ("label" in item && "value" in item) {
      return (
        <Checkbox
          disabled={disabled}
          key={i}
          label={item.label}
          value={item.value}
        />
      );
    } else if ("group" in item && "items" in item) {
      return renderCheckboxGroup(item, i);
    }
  };
  const renderCheckboxGroup = (
    item: { group: string; items: (string | ComboboxItem)[] },
    i: string | number,
  ) => (
    <div key={i}>
      <strong>{item.group}</strong>
      {item.items.map((groupItem, groupIndex) =>
        renderCheckbox(groupItem, `${i}-${groupIndex}`),
      )}
    </div>
  );
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CheckboxGroup {...field} {...checkboxProps} error={error?.message}>
          <CheckBoxWrapper>
            {data.map((item, i) => renderCheckbox(item, i))}
          </CheckBoxWrapper>
        </CheckboxGroup>
      )}
    />
  );
}
