import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";

import {
  FileInputProps as MantineFileProps,
  FileInput as MantineFileInput,
} from "@mantine/core";

export type FileInputProps = MantineFileProps;

type Props<FORM_TYPE extends FieldValues, Multiple extends boolean = false> = {
  onChange?:
    | ((payload: Multiple extends true ? File[] : File | null) => void)
    | ((
        event: ChangeEvent<Element> | PathValue<FORM_TYPE, Path<FORM_TYPE>>,
      ) => void);
  value?: string;
} & Omit<FileInputProps, "onChange">;

export const FileInput = forwardRef(function FileInput<
  FORM_TYPE extends FieldValues,
>(props: Props<FORM_TYPE>, ref: ForwardedRef<HTMLButtonElement>) {
  const { ...fileInputProps } = props;
  const defaultOption: FileInputProps = {};
  const option = Object.assign({}, defaultOption, fileInputProps);
  return <MantineFileInput {...option} ref={ref} />;
});
