import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { useDisclosure } from "@mantine/hooks";
import { format } from "date-fns";

import { Input, InputProps } from "@/components/ui/elements";
import { DatePicker } from "@/components/ui/parts";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
} & Omit<InputProps, "name">;

export function DatePickerField<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
) {
  const { control, name, ...inputProps } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const [opend, { open, close }] = useDisclosure();
  return (
    <DatePicker
      opend={opend}
      onChange={field.onChange}
      value={field.value}
      defaultValue={field.value}
    >
      <Input
        onClick={open}
        id={name}
        {...inputProps}
        onBlur={close}
        ref={field.ref}
        value={field.value ? format(new Date(field.value), "yyyy-MM-dd") : ""}
        onChange={field.onChange}
        error={error?.message}
      />
    </DatePicker>
  );
}
