import { HTMLInputTypeAttribute, ReactNode } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import {
  Input,
  InputProps,
  NumberInput,
  NumberInputProps,
} from "@/components/ui/elements";

type InputFieldProps =
  | Omit<InputProps, "name">
  | (NumberInputProps & { type: "number" });

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
  type?: HTMLInputTypeAttribute;
  label?: ReactNode;
} & InputFieldProps;

export function InputField<FORM_TYPE extends FieldValues>(
  props: Props<FORM_TYPE>,
): JSX.Element {
  const { control, name, type, ...inputProps } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  if (type === "number") {
    const { ...inputNumberProps } = inputProps as Omit<
      NumberInputProps,
      "name"
    >;
    return (
      <NumberInput
        id={name}
        {...inputNumberProps}
        ref={field.ref}
        value={field.value}
        onChange={field.onChange}
        error={error?.message}
      />
    );
  }

  return (
    <Input
      id={name}
      type={type}
      {...inputProps}
      ref={field.ref}
      value={field.value}
      onChange={field.onChange}
      error={error?.message}
    />
  );
}
