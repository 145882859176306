import {
  CenterProps,
  Center as MantaineCenter,
  MantineStyleProps,
  createPolymorphicComponent,
} from "@mantine/core";

type Props = {
  children: React.ReactNode;
} & Omit<CenterProps, "children"> &
  MantineStyleProps;

function _Center(props: Props) {
  const { children, ...centerProps } = props;
  const defaultOption: Omit<CenterProps, "children"> = {};
  const option = Object.assign({}, defaultOption, centerProps);
  return <MantaineCenter {...option}>{children}</MantaineCenter>;
}

export const Center = createPolymorphicComponent<
  "div",
  Omit<CenterProps, "children">
>(_Center);
